import { render, staticRenderFns } from "./ProducersTableActions.vue?vue&type=template&id=4ec61f4f&"
import script from "./ProducersTableActions.vue?vue&type=script&lang=js&"
export * from "./ProducersTableActions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports