<template>
  <dropdown-menu :items="actions" :select="onActionSelect">
    <font-awesome-icon icon="ellipsis-h"
  /></dropdown-menu>
</template>

<script>
import EventBus from "../../../../../../helpers/EventBus";
import { filtersMap } from "../../filtersMap";
import { PERMS } from "../../../../../../helpers/acl";
import {
  INCIDENT_ENTITY_TYPE,
  INCIDENT_TYPE,
  ORDER_ACTION,
} from "../../../../../../config/global";

export default {
  name: "OrdersTableActions",

  data() {
    const canEdit = this.canView(PERMS.ORDERS_EDIT);
    return {
      actions: [
        { id: ORDER_ACTION.FILTER, text: "Filtra" },
        {
          id: ORDER_ACTION.DONE,
          text: "Segna come pronto",
          disabled: !canEdit,
        },
        {
          id: ORDER_ACTION.UNDONE,
          text: "Segna come NON pronto",
          disabled: !canEdit,
        },
        {
          id: ORDER_ACTION.INCIDENT,
          text: "Apri segnalazione",
          disabled: !canEdit,
        },
        {
          id: ORDER_ACTION.CANCELLATION,
          text: "Annullato",
          disabled: !canEdit,
        },
      ],
    };
  },
  methods: {
    onActionSelect: function (args) {
      const { id } = args.item;

      const plists = [this.data.id];

      const onSuccess = (response) => {
        EventBus.$emit("orders:refresh");
      };

      if (id === ORDER_ACTION.FILTER) {
        const param = {
          id: this.data.producer.id,
          label: this.data.producer.role.name,
        };
        this.$store.commit("rounds/updateProducerId", param);
        EventBus.$emit("orders:applyFilters");
      }

      if (id === ORDER_ACTION.DONE) {
        this.$store
          .dispatch("rounds/updateStatusBatch", {
            status: filtersMap("wh.statusCode.next"),
            plists,
          })
          .then(onSuccess);
      }

      if (id === ORDER_ACTION.UNDONE) {
        this.$store
          .dispatch("rounds/updateStatusBatch", {
            status: filtersMap("wh.statusCode.prev"),
            plists,
          })
          .then(onSuccess);
      }

      if (args.item.id === ORDER_ACTION.INCIDENT) {
        this.$store.commit("incidents/createResetAllEntities");
        this.$store.commit("incidents/createAddEntities", {
          entities: [
            { type: INCIDENT_ENTITY_TYPE.PLISTS, items: [{ ...this.data }] },
          ],
          type: INCIDENT_TYPE.INCIDENT,
          show: true,
        });
      }

      if (args.item.id === ORDER_ACTION.CANCELLATION) {
        this.$store.commit("incidents/createResetAllEntities");
        this.$store.commit("incidents/createAddEntities", {
          entities: [
            { type: INCIDENT_ENTITY_TYPE.PLISTS, items: [{ ...this.data }] },
          ],
          type: INCIDENT_TYPE.CANCELLATION,
          show: true,
        });
      }
    },

    canView(perm) {
      return this.$store.getters["connections/havePermission"](perm);
    },
  },
};
</script>
